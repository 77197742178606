import styled from '@emotion/styled'
import { Button } from '@mui/material'
import { Stack } from '@mui/system'
import { ThemeColor, theme } from '../../style/theme'

type Props = {
  bgColor?: ThemeColor
  largeContainer?: boolean
}

export const StyledModalContent = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'largeContainer'
})<{ largeContainer?: boolean }>(({ largeContainer }) => ({
  backgroundColor: 'white',
  width: largeContainer ? '400px' : 'auto',
  position: 'fixed',
  padding: '15px 25px',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  maxHeight: '90vh',
  overflow: 'scroll',
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  borderRadius: '14px',
  '::-webkit-scrollbar': {
    display: 'none'
  }
}))

export const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'bgColor'
})(({ bgColor = 'danger' }: Props) => ({
  verticalAlign: 'center',
  backgroundColor: `${theme.palette[bgColor]}`,
  fontFamily: 'Lato, sans-serif',
  fontSize: '14px',
  padding: '6px 24px',
  color: 'white',
  justifyContent: 'center',
  borderRadius: '10px',
  fontWeight: 700,
  boxShadow: 'none',
  width: 'fit-content',
  '&:hover': {
    opacity: '0.8',
    backgroundColor: `${theme.palette[bgColor]}`,
    boxShadow: 'none'
  }
}))
