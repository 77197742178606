import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ErrorIcon from '@mui/icons-material/Error'
import { Card, Container, InputLabel, Toolbar } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '../../components/Button'
import CustomModal from '../../components/CustomModal'
import Loader from '../../components/Loader'
import Stack from '../../components/Stack'
import { Text } from '../../components/Text'
import { appActions } from '../../features/app/reducer'
import { selectModalMessage } from '../../features/app/selectors'
import { productsActions } from '../../features/products/reducer'
import { deleteCategories, deleteProducts } from '../../utils'

// deleteCategories()
// deleteProducts()

function CSVImport() {
  const dispatch = useDispatch()
  const modalMessage = useSelector(selectModalMessage)

  const handleSubmit = async () => {
    try {
      dispatch(productsActions.importProducts())
    } catch (submissionError) {
      if (typeof submissionError === 'string' || Array.isArray(submissionError)) {
        dispatch(appActions.showModalMessage({ type: 'error', message: submissionError }))
      } else {
        console.error('submissionError', submissionError)
      }
    }
  }

  return (
    <Container>
      <Toolbar variant="regular" />
      <Card sx={{ padding: '1rem' }}>
        <Stack gap={2}>
          <Stack
            gap={2}
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <InputLabel>Importa Magazzino</InputLabel>
            <CustomModal
              onClose={() => dispatch(appActions.hideModalMessage())}
              openModal={
                <Button
                  borderRadius={16}
                  variant="outlined"
                  title="Importa"
                  onClick={handleSubmit}
                />
              }
            >
              <Toolbar variant="dense">
                {modalMessage?.type === 'error' ? (
                  <ErrorIcon color="error" />
                ) : modalMessage?.type === 'success' ? (
                  <CheckCircleIcon color="success" />
                ) : (
                  <Loader showLoader={!modalMessage} />
                )}
              </Toolbar>
              {modalMessage?.message && typeof modalMessage?.message === 'string' ? (
                <Text text={modalMessage?.message} />
              ) : Array.isArray(modalMessage?.message) ? (
                modalMessage?.message.map((message, index) => (
                  <Text text={message} key={index} />
                ))
              ) : null}
            </CustomModal>
          </Stack>
        </Stack>
      </Card>
    </Container>
  )
}

export default CSVImport
