import { keyBy } from 'lodash'
import _uniqBy from 'lodash/uniqBy'
import { BSON } from 'realm-web'
import { getAllCategories, getAllProducts } from '../api/iPratico'
import { Category } from '../features/categories/model'
import { Product } from '../features/products/model'

const mapProduct = (product: Record<string, any>) => ({
  _id: product.id || null,
  title: product.value.name || null,
  img: product.value.productPresentation?.galleryRefs?.[0] || null,
  quantity: 1,
  category: product.value.productCategoryId ? [product.value.productCategoryId] : null,
  time: null,
  sku:
    product.value.productCodes?.find((p: Record<string, string>) => p.codeType === 'SKU')
      ?.code || null,
  conversionValue: 1,
  weight: product.value.unit ? 1 : 0,
  conversionUnit: product.value.unit ? product.value.unit : 'pezzo',
  price: product.value.pricesArray?.[0]?.elements?.[0]?.price || 0
})

export const FetchIpraticoProducts = async (categories: Category[]) => {
  try {
    const iPraticoProducts = await getProducts()
    const products = iPraticoProducts?.map((prod) => ({
      ...prod,
      category: [prod.category[0]?.name]
    }))
    const currentCategories = _uniqBy(
      iPraticoProducts,
      (iPraticoProducts) => iPraticoProducts.category[0]?.name
    )
    const missingCategories = currentCategories
      .filter((product) => {
        const categoryName = product.category[0]?.name
        return !Object.values(categories).some(
          (category) => category.name === categoryName
        )
      })
      .map((product) => ({ _id: new BSON.ObjectId(), name: product.category[0]?.name }))

    return { products, missingCategories }
  } catch (err) {
    console.error(err)
  }
}

const getProducts = async () => {
  try {
    const productResponse: Record<string, any> = await getAllProducts()
    const products: Product[] = productResponse.data?.map(mapProduct)

    const categories = await getCategories()

    const productsWithCategoryNames = products
      .filter(
        (product) =>
          product.category && product.category.length > 0 && product.sku !== null
      )
      .map((product) => {
        const productCategoriesWithNames = product.category?.map((categoryId) => {
          const matchingCategory = categories[categoryId]
          return matchingCategory
            ? { _id: categoryId, name: matchingCategory.name }
            : null
        })

        return {
          ...product,
          category: productCategoriesWithNames || []
        }
      })
    return productsWithCategoryNames
  } catch (err) {
    console.error(err)
  }
}

const getCategories = async () => {
  try {
    const categoryResponse: Record<string, any> = await getAllCategories()
    const mappedCategory: Category[] = categoryResponse.data?.map(
      ({ id, value: { name } }: { id: string; value: { name: string } }) => ({
        _id: id,
        name
      })
    )
    const keyByMappedCategory = keyBy(mappedCategory, '_id')
    return keyByMappedCategory
  } catch (err) {
    console.error(err)
    throw err
  }
}
