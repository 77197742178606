import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit'
import { ProductDTO } from '../../utils/convertExcelToJson'
import { Product } from '../products/model'
import { Category, CategoriesSlice, CategoryDTO } from './model'

const initialState: CategoriesSlice = {
  items: []
}

export const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    categoriesLoaded(state, { payload }: PayloadAction<Category[] | CategoryDTO[]>) {
      state.items = payload.map((c) => ({ ...c, _id: c._id.toString() }))
    }
  }
})

export const categoriesActions = {
  ...categoriesSlice.actions,
  fetchCategories: createAction('categories/fetchCategories'),
  addCategoriesRequested: createAction<{
    categories: CategoryDTO[]
    products: Product[]
  }>('categories/addCategoriesRequested')
}
export const categoriesReducer = categoriesSlice.reducer
