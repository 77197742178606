import { Dictionary } from '@reduxjs/toolkit'
import { capitalize, chain, keyBy, map, update, upperCase } from 'lodash'
import { BSON } from 'realm-web'
import { Category, CategoryDTO } from '../features/categories/model'
import { Order, Orders } from '../features/orders/model'
import { OrderProduct } from '../features/ordersProducts/model'
import { Products } from '../features/products/model'
import { Store } from '../features/stores/model'
import { app, mongodb } from '../mongodb'
import { ProductDTO } from './convertExcelToJson'

export const isUserLoggedIn = async () => {
  try {
    const user = await app.currentUser
    if (user == null) return false
    return await user.isLoggedIn
  } catch (error) {
    console.log('Failed to fetch user', error)
  }
}

export const getOrders = async () => {
  const res = await mongodb().collection('orders').find({})
  return res as Orders
}

export const deleteProducts = async () => {
  const res = await mongodb().collection('products').deleteMany({})
  return res
}
export const updateProducts = async (products: Products) => {
  const coll = mongodb().collection('products')
  const updatePromises = products.map(async ({ _id, ...updatedData }) => {
    const result = await coll.updateOne(
      { _id: new BSON.ObjectId(_id) },
      { $set: updatedData }
    )
    return result
  })
  await Promise.all(updatePromises)
}

export const deleteCategories = async () => {
  const res = await mongodb().collection('categories').deleteMany({})
  return res
}

export const getOrderById = async (id: string) => {
  const res = await mongodb()
    .collection('orders')
    .findOne({
      _id: new BSON.ObjectId(id)
    })
  return res as Order
}

export const getProducts = async () => {
  const res = await mongodb().collection('products').find({})
  return res as Products
}

export const createProducts = async (products: ProductDTO[]) => {
  const res = await mongodb().collection('products').insertMany(products)
  return res
}

export const getCategories = async () => {
  const res = await mongodb().collection('categories').find({})
  return res as Category[]
}

export const createCategories = async (categories: CategoryDTO[]) => {
  const res = await mongodb().collection('categories').insertMany(categories)
  return res
}

export const getOrderProducts = async () => {
  const res = await mongodb().collection('orders-products').find({})
  return res as OrderProduct[]
}

export const getStores = async () => {
  const res = await mongodb().collection('stores').find({})
  return res as Store[]
}

export const createOrder = async (order: Order) => {
  const res = await mongodb().collection('orders').insertOne(order)
  return res.insertedId.toString()
}

export const addOrderProducts = async (products: OrderProduct[]) => {
  const res = await mongodb().collection('orders-products').insertMany(products)
  return res.insertedIds.toString()
}

export const deleteOrder = async (order: Order) => {
  await mongodb()
    .collection('orders-products')
    .updateMany({ orderId: order._id?.toString() }, { $set: { delete: true } })
  return await mongodb()
    .collection('orders')
    .updateOne({ _id: order._id }, { $set: { delete: true } })
}

export const updateOrderAddress = async (orderId: string, storeId: string) => {
  const result = await mongodb()
    .collection('orders')
    .updateOne(
      { _id: new BSON.ObjectId(orderId) },
      { $set: { 'details.deliveryStoreId': storeId } }
    )
  return result
}

export const updateDeliveryDate = async (order: Order & { newDeliveryDate: string }) => {
  const deliveryDate = order.newDeliveryDate
  const details = {
    ...order?.details,
    deliveryDate
  }
  try {
    await mongodb().collection('orders-products').updateMany(
      { orderId: order._id },
      {
        $set: { deliveryDate }
      }
    )
    return await mongodb()
      .collection('orders')
      .updateOne({ _id: new BSON.ObjectId(order._id) }, { $set: { details } })
  } catch (err) {
    console.log(err)
  }
}

export const updateOrderProductsQuantity = async (product: OrderProduct) => {
  if (!product.tempQuantity) return
  const res = await mongodb()
    .collection('orders-products')
    .updateOne(
      { _id: new BSON.ObjectId(product._id) },
      {
        $set: {
          quantity: product.tempQuantity,
          updateAt: new Date()
        }
      }
    )
  return res
}

export const addStore = () => {
  mongodb().collection('stores').insertOne({
    name: ' Via Baylle 25',
    phone: '+39 070674043',
    img: ' ',
    createdAt: new Date()
  })
}

export const addProduct = async () => {
  const product = {
    img: '',
    title: '',
    category: []
  }
  const res = await mongodb().collection('products').insertOne(product)
  return res.insertedId.toString()
}

export const getFormattedDate = (value: string) => {
  const date = new Date(value).toLocaleDateString('it-IT')
  const today = new Date().toLocaleDateString('it-IT')
  const tomorrow = new Date(
    new Date().setDate(new Date().getDate() + 1)
  ).toLocaleDateString('it-IT')

  if (date === today) {
    return 'Oggi'
  }
  if (date === tomorrow) {
    return 'Domani'
  }
  const day = new Date(value).toLocaleDateString('it-IT', { weekday: 'long' })
  const month = new Date(value).toLocaleDateString('it-IT', { month: 'long' })
  return `${capitalize(day)} ${new Date(value).getDate()} ${capitalize(month)}`
}

export const getWeekDay = (value: string) => {
  const date = new Date(value).toLocaleDateString('it-IT')
  const today = new Date().toLocaleDateString('it-IT')
  const tomorrow = new Date(
    new Date().setDate(new Date().getDate() + 1)
  ).toLocaleDateString('it-IT')

  if (date === today) {
    return 'Oggi'
  }
  if (date === tomorrow) {
    return 'Domani'
  }
  return `${capitalize(new Date(value).toLocaleDateString('it-IT', { weekday: 'long' }))}`
}

export const getMonth = (date: string) => {
  return `${upperCase(
    new Date(date).toLocaleDateString('it-IT', { month: 'long' })
  ).substring(0, 3)}`
}

export const uploadProducts = async (
  products: Products,
  categories: Dictionary<Category>,
  existingProducts: Products,
  missingCategories: Dictionary<Category> = {}
) => {
  const skuSet = new Set(existingProducts.map((product) => product.sku))
  const newSkus = products.filter((product) => !skuSet.has(product.sku))
  const keyByCategories = keyBy(categories, 'name')

  const mappedProducts: ProductDTO[] = map(newSkus, (product) => {
    const relativeCategories = chain(product.category)
      .map((categoryName) => {
        const category = keyByCategories[categoryName] ?? missingCategories[categoryName]
        return category?._id.toString()
      })
      .compact() // removes any "undefined" element from the array
      .value()

    if (relativeCategories.length) {
      product = update({ ...product }, 'category', () => relativeCategories)
    }
    return checkedProduct(product)
  })

  const updatedExistingProducts = existingProducts.map((product) => {
    const matchedProduct = products.find(
      (matchedProduct) => matchedProduct.sku === product.sku && product.sku !== null
    )
    if (matchedProduct) {
      return {
        ...matchedProduct,
        _id: product._id,
        category: product.category
      }
    }
    return product
  })
  await updateProducts(updatedExistingProducts)

  if (mappedProducts.length === 0) {
    const response = {
      message: `Prodotti aggiunti 0. Prodotti aggiornati ${updatedExistingProducts.length}`,
      type: 'success'
    }
    return response
  }
  // throw new Error(
  //   `Prodotti aggiunti 0. Prodotti aggiornati ${updatedExistingProducts.length}`
  // )

  const createdProducts = await createProducts(mappedProducts)

  if (products.length !== mappedProducts.length) {
    const response = {
      message: `Prodotti aggiunti: ${createdProducts.insertedIds.length}. Prodotti aggiornati ${updatedExistingProducts.length}`,
      type: 'success'
    }
    return response
  }
  // throw new Error(
  //   ` Prodotti aggiunti: ${createdProducts.insertedIds.length}. Prodotti aggiornati ${updatedExistingProducts.length}`
  // )

  if (createdProducts.insertedIds.length !== products.length) {
    const response = {
      message: "Errore durante l'upload",
      type: 'error'
    }
    return response
  }
  const response = {
    message: `Prodotti aggiunti: ${createdProducts.insertedIds.length}. Prodotti aggiornati ${updatedExistingProducts.length}`,
    type: 'success'
  }
  return response
  // throw new Error("Errore durante l'upload")
}

const checkedProduct = (product: ProductDTO) => {
  return {
    title: product.title || '',
    category: product.category,
    img: product.img || '',
    sku: product.sku,
    weight: product.weight || 0,
    conversionUnit: product.conversionUnit || 'kg',
    conversionValue: product.conversionValue || 1,
    price: product.price || 0,
    // noFood: product.noFood !todo: active this and removed the next one
    noFood: true
  }
}
