import axios, { AxiosRequestConfig } from 'axios'

const baseURLIpratico = process.env.REACT_APP_IPRATICO_BASE_URL
console.log('🚀 ~ baseURLIpratico:', baseURLIpratico)
axios.defaults.headers.common['x-api-key'] = process.env.REACT_APP_IPRATICO_PRINCIPALE

const createHttpClient = ({ baseURL, params }: AxiosRequestConfig) =>
  axios.create({ baseURL, params })

export const apiClient = createHttpClient({
  baseURL: process.env.REACT_APP_IPRATICO_BASE_URL
})
