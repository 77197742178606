import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { getWarehouseItems, putWarehouseItems } from '../../api/iPratico'
import { Order } from '../orders/model'
import {
  makeSelectOrderProductsByOrderId,
  selectCurrentOrderWithStore
} from '../orders/selectors'
import { OrderProduct } from '../ordersProducts/model'
import { Product } from '../products/model'
import { makeSelectProductById } from '../products/selectors'
import { Store } from '../stores/model'

export const startIpraticoSaga = () => ({
  type: 'START_IPRATICO_SAGA'
})

function* getProductByIdGenerator(id: string) {
  const product: Product = yield select(makeSelectProductById(id))
  return product
}

function* updateIpraticoQtySaga({ payload }: any) {
  const { flowName } = payload || {}

  const order: Order & { deliveryStore: Store } = yield select(
    selectCurrentOrderWithStore
  )
  const orderID = order?._id
  const store_apikey = order.deliveryStore.iPratico

  const orderProducts: OrderProduct[] = yield select(
    makeSelectOrderProductsByOrderId(orderID)
  )
  // mix Product with OrderProduct.quantity so we have sku and quantity in the same place
  const products: Product[] = yield all(
    orderProducts.map((p) =>
      call(function* () {
        const product: Product = yield call(getProductByIdGenerator, p.productId)
        return { ...product, quantity: p.quantity }
      })
    )
  )
  // get all warehouse items from iPratico api
  const { data: AllWarehouseProducts } = yield call(getWarehouseItems, store_apikey)
  // filter warehouse items from iPratico to match our products in order
  const matchWarehouseProducts: Record<string, any>[] = AllWarehouseProducts.filter(
    (p: Record<string, any>) => {
      const onlyLocalSkus = products.map((p) => p.sku)
      const currentRemoteSku =
        p?.product?.value?.productCodes?.find(
          (p: Record<string, any>) => p.codeType === 'SKU'
        )?.code || null
      return onlyLocalSkus.includes(currentRemoteSku)
    }
  )
  // for each product, fire a put request to update stock quatity (our qty + iPratico item qty)
  const updateProductRequests = products.map((product: Product) => {
    const foundWarehouseProduct = matchWarehouseProducts.find(
      (p) =>
        p.product.value.productCodes.find(
          (pp: Record<string, any>) => pp.codeType === 'SKU'
        ).code === product.sku
    )
    const updateQuantity =
      foundWarehouseProduct?.value?.availableStockQuantity + product.quantity
    return call(putWarehouseItems, {
      id: foundWarehouseProduct?.id,
      body: {
        productId: foundWarehouseProduct?.value.productId,
        cas: foundWarehouseProduct?.cas,
        availableStockQuantity: updateQuantity
      },
      apiKey: store_apikey
    })
  })
  // @ts-ignore
  const results: any = yield all(updateProductRequests)

  if (flowName) yield put({ type: 'flower/next', payload: { name: flowName } })
}

export function* iPraticoSaga() {
  yield takeLatest(startIpraticoSaga().type, updateIpraticoQtySaga)
}
