import { put, call, takeEvery, select } from 'redux-saga/effects'
import { createCategories, getCategories, uploadProducts } from '../../utils'
import { ModalMessage } from '../app/model'
import { appActions } from '../app/reducer'
import { Product, Products } from '../products/model'
import { productsActions } from '../products/reducer'
import { selectCategoriesMappedByName, selectProducts } from '../products/selectors'
import { Category } from './model'
import { categoriesActions } from './reducer'

function* fetchCategories(action: ReturnType<typeof categoriesActions.fetchCategories>) {
  const categories: Category[] = yield call(getCategories)
  yield put(categoriesActions.categoriesLoaded(categories))
}

function* addCategories({
  payload
}: ReturnType<typeof categoriesActions.addCategoriesRequested>) {
  const { products, categories } = payload
  try {
    yield call(createCategories, categories)
    yield put(categoriesActions.fetchCategories())
    const categoriesMappedByName: ReturnType<typeof selectCategoriesMappedByName> =
      categories.reduce(
        (acc, { name, ...rest }) => ({ ...acc, [name]: { ...rest, name } }),
        {}
      )
    const categoriesFromStore: ReturnType<typeof selectCategoriesMappedByName> =
      yield select(selectCategoriesMappedByName)
    const existingProduct: Products = yield select(selectProducts)
    const response: ModalMessage = yield uploadProducts(
      products,
      categoriesFromStore,
      existingProduct,
      categoriesMappedByName
    )
    // yield put(productsActions.fetchProducts())
    // yield put(categoriesActions.fetchCategories())
    yield put(appActions.showModalMessage(response))
  } catch (error) {
    if (error instanceof Error)
      yield put(appActions.showModalMessage({ type: 'error', message: error.message }))
    else if (typeof error === 'string' || Array.isArray(error))
      yield put(appActions.showModalMessage({ type: 'error', message: error }))
    else console.log(error)
  }
}

export function* categoriesSaga() {
  yield takeEvery(categoriesActions.fetchCategories.toString(), fetchCategories)
  yield takeEvery(categoriesActions.addCategoriesRequested.toString(), addCategories)
}
