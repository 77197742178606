import { keyBy } from 'lodash'
import { put, call, takeEvery, select, take } from 'redux-saga/effects'
import { getProducts, uploadProducts } from '../../utils'
import { checkCategory } from '../../utils/checkCategory'
import { FetchIpraticoProducts } from '../../utils/iPratico'
import { ModalMessage } from '../app/model'
import { appActions } from '../app/reducer'
import { Category, CategoryDTO } from '../categories/model'
import { categoriesActions } from '../categories/reducer'
import { Products } from './model'

import { productsActions } from './reducer'
import {
  selectCategories,
  selectCategoriesMappedByName,
  selectProducts
} from './selectors'

function* fetchProducts(action: ReturnType<typeof productsActions.fetchProducts>) {
  const products: Products = yield call(getProducts)
  yield put(productsActions.productsLoaded(products))
}

function* importIpraticoProducts() {
  try {
    const categories: Category[] = yield select(selectCategories)
    const existingProduct: Products = yield select(selectProducts)

    const newProductAndMissingCategories: Record<string, any> =
      yield FetchIpraticoProducts(categories)
    const newProducts: Products = newProductAndMissingCategories.products
    const missingCategories = newProductAndMissingCategories.missingCategories

    if (missingCategories.length > 0) {
      yield put(
        categoriesActions.addCategoriesRequested({
          categories: missingCategories,
          products: newProducts
        })
      )
      yield take(categoriesActions.categoriesLoaded) // wait for action to be completed
    } else {
      const keyByCategories = keyBy(categories, '_id')
      const response: ModalMessage = yield uploadProducts(
        newProducts,
        keyByCategories,
        existingProduct
      )
      yield put(appActions.showModalMessage(response))
    }
    yield put(categoriesActions.fetchCategories())
    yield put(productsActions.fetchProducts())
  } catch (error) {
    if (error instanceof Error)
      yield put(appActions.showModalMessage({ type: 'error', message: error.message }))
    else if (typeof error === 'string' || Array.isArray(error))
      yield put(appActions.showModalMessage({ type: 'error', message: error }))
    else console.log(error)
  }
}

// function* addProducts(action: ReturnType<typeof productsActions.addProductsRequested>) {
//   try {
//     const categories: Category[] = yield select(selectCategories)
//     const existingProduct: Products = yield select(selectProducts)

//     const newProductAndMissingCategories: Record<string, any> =
//       yield FetchIpraticoProducts(categories)
//     const newProducts = newProductAndMissingCategories.products
//     const missingCategories = newProductAndMissingCategories.missingCategories

//     console.log('missing categories = ', missingCategories, newProducts)

//     if (missingCategories.length > 0) {
//       yield put(
//         categoriesActions.addCategoriesRequested({
//           categories: missingCategories,
//           products: newProducts
//         })
//       )
//       // yield put(categoriesActions.fetchCategories())
//     } else {
//       // yield uploadProducts(newProducts, categories, existingProduct)
//     }

//     yield put(appActions.showModalMessage({ type: 'success', message: 'Fatto!' }))
//   } catch (error) {
//     if (error instanceof Error)
//       yield put(appActions.showModalMessage({ type: 'error', message: error.message }))
//     else if (typeof error === 'string' || Array.isArray(error))
//       yield put(appActions.showModalMessage({ type: 'error', message: error }))
//     else console.log(error)
//   }
// }

export function* productSaga() {
  yield takeEvery(productsActions.fetchProducts.toString(), fetchProducts)
  // yield takeEvery(productsActions.addProductsRequested.toString(), addProducts)
  yield takeEvery(productsActions.importProducts.toString(), importIpraticoProducts)
}
