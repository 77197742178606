import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ProductDTO } from '../../utils/convertExcelToJson'
import { Products, ProductSlice } from './model'

const initialState: ProductSlice = {
  importedItems: [],
  items: [],
  filters: {
    category: ''
  },
  search: ''
}

export const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    productsLoaded(state, { payload }: PayloadAction<Products>) {
      state.items = payload.map((r) => ({ ...r, _id: r._id.toString() }))
    },
    productsImported(state, { payload }: PayloadAction<Products>) {
      state.importedItems = payload.map((r) => ({ ...r, _id: r._id.toString() }))
    },
    searchedProducts(state, { payload }: PayloadAction<string>) {
      state.search = payload
      state.filters.category = ''
    },
    setFilter(state, { payload }: PayloadAction<string | undefined>) {
      state.filters.category = state.filters.category === payload ? '' : payload
      state.search = ''
    }
  }
})

export const productsActions = {
  ...productsSlice.actions,
  addProductsRequested: createAction<ProductDTO[]>('products/addProductsRequested'),
  fetchProducts: createAction('products/fetchProducts'),
  importProducts: createAction('products/importProducts')
}
export const productsReducer = productsSlice.reducer
