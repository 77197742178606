import { AxiosResponse } from 'axios'
import { apiClient } from './client'

export const getAllProducts = (): Promise<AxiosResponse> => {
  return apiClient.get('/products')
}
export const getAllCategories = (): Promise<AxiosResponse> => {
  return apiClient.get('/product-categories')
}

export const getWarehouseItems = (apiKey: string): Promise<AxiosResponse> => {
  return apiClient.get('/warehouse-items', {
    headers: {
      'x-api-key': apiKey
    }
  })
}

export const getWarehouseItem = ({
  apiKey,
  id
}: {
  apiKey: string
  id: string
}): Promise<AxiosResponse> => {
  return apiClient.get(`/warehouse-items/${id}`, {
    headers: {
      'x-api-key': apiKey
    }
  })
}

export const putWarehouseItems = ({
  id,
  body,
  apiKey
}: {
  id: string
  body: any
  apiKey: string
}): Promise<AxiosResponse> => {
  return apiClient.put(`/warehouse-items/${id}`, body, {
    headers: {
      'x-api-key': apiKey
    }
  })
}
